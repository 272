html {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  box-sizing: border-box;
  background: #f5f8fa;
  font-family: 'Open Sans', 'Nunito';
}

ins {
  background-image: linear-gradient(to right, #1de9b6 0%, #1de9b6 100%);
}
ins {
  background-color: transparent;
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 100% 0.55em;
  font-weight: bolder;
  text-decoration: none;
  transition: background 0.3s;
}
.email-highlight {
  background-color: transparent;
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 100% 0.05em;
  font-weight: bolder;
  text-decoration: none;
  transition: background 0.3s;
}
ins.ins-js {
  background-image: linear-gradient(to right, #ffd600 0%, #ffd600 100%);
}
img {
  object-fit: cover;
}
.right a {
  color: #152738;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
  background: #e5e5e5;
  padding: 20px 0px;
  text-align: center;
  transition: all 0.5s;
}

a {
  text-decoration: none;
}
