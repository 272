@import url('https://fonts.googleapis.com/css?family=Space+Mono');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:600');

span {
  font-family: 'Open Sans';
}
.App {
  overflow: hidden;
  background: $theme;
  font-family: 'Space Mono', monospace;
}
.hero {
  color: $gray;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  position: relative;
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 33px;
  }
}

.blue-tag {
  color: $blue;
}

.social-media {
  margin: 2rem;
  svg {
    padding: 0.8rem;
    font-size: 3rem;
    transition: 0.7s all;
    &:hover {
      transform: scale(1.5);
    }
  }
}
.arrow-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 7rem;
  margin-top: 5rem;
}
.arrows {
  max-width: 110px;
  span {
    display: block;
    width: 45px;
    height: 45px;
    border-bottom: 1px solid $blue-light;
    border-right: 1px solid $blue-light;
    transform: rotate(45deg);
    margin-top: -10px;
    animation: animate 2s infinite;
  }
}
.arrows span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrows span:nth-child(3) {
  animation-delay: -0.4s;
}
.about {
  color: $gray;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;

  &__title {
    margin-bottom: 2rem;
    font-family: 'Space Mono', monospace;
  }
  &__blog-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    box-shadow: 0 0 7px -3px #ccc;
    padding-bottom: 1.5rem;
    width: 40%;
    img {
      width: 100%;
      height: 230px;
      object-fit: cover;
    }
    h2,
    p,
    span {
      margin-bottom: 1rem;
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .link-to-medium {
      text-decoration: none;
      color: $blue;
    }
    .blog-date {
      color: #444;
    }
    .bordered-button {
      border: 1px solid $blue;
      border-radius: 3px;
      padding: 0.7rem 0.5rem;
      margin-right: 1rem;
      align-self: flex-end;
      svg {
        margin-right: 0.5rem;
        &:hover {
          transform: scale(1);
        }
      }
      &:hover .transition-color {
        width: 100%;
      }
    }
    .transition-color {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: $blue-lightest;
      -webkit-transition: width 0.3s;
      transition: width 0.3s;
      -webkit-transition-timing-function: ease;
      transition-timing-function: ease;
    }
  }
}
.contact-me {
  margin: 4rem 0;
  background: rgba(211, 211, 211, 0.02);
  padding: 4rem 0;
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    max-width: 650px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
    color: $gray;
    font-size: 1.2rem;
    p {
      margin-bottom: 1rem;
      margin-top: 0;
    }
    a {
      text-decoration: none;
      color: $gray;
    }
  }
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

@media (max-width: 600px) {
  .hero {
    h1 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .social-media svg {
    font-size: 2rem;
  }
}
@media (max-width: 320px) {
  .hero {
    h1 {
      font-size: 1.2rem;
    }
    p {
      font-size: 1rem;
    }
  }
}

.linkedin {
  color: #0077b5;
}
.medium {
  color: #279e6e;
}
.github {
  color: #666666;
}
.insta {
  color: #7232bd;
}
