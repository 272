//=====================================================
//                    Variables
// ===================================================
$white: #fff;
$theme: rgb(10, 12, 16);
$gray: rgb(164, 162, 157);
$color-primary: #1de9b6;
$color-primary-light: lighten($color-primary, 40%);
$color-secondary: #4a4a4a;
$color-secondary-light: lighten($color-secondary, 40%);
$color-accent: #e5e5e5;
$color-accent-light: lighten($color-accent, 8%);

$text-shadow: 0 1px 1px #4f5a65;
$box-shadow: 0px 3px 5px $color-secondary-light;

//! Colors for the editor section

$red: #fa4646;
$yellow: rgb(253, 196, 10);
$orange: rgb(248, 121, 44);
$green: rgb(184, 210, 49);
$blue: rgb(57, 173, 226);
$blue-light: rgba(57, 173, 226, 0.8);
$blue-lightest: rgba(57, 173, 226, 0.2);
