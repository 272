footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  color: $gray;
  width: 100%;
  position: absolute;
  bottom: 0;
  p {
    text-align: center;
    margin: 1rem;
    flex-basis: 100%;
    & i {
      animation: heartbeat 1s infinite;
    }
  }
}
